.asidemenuadmin ul {
  list-style: none;
  padding: 0;
  border: solid 1px rgb(204, 204, 204);
  border-radius: 5px;
}
.asidemenuadmin > ul > li > i {
  display: block;
}

.asidemenuadmin > ul > li:hover {
  font-weight: 600;
  transition-duration: 0.1s;
  color: #003876;
  border-left: 2px solid #003876;
}

.asidemenuadmin > ul > li {
  border-bottom: solid 1px rgb(204, 204, 204);
  position: relative;
  padding: 10px 10px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s;
  cursor: pointer;
  
}

.asidemenuadmin > ul > li > ul > li:hover {
  background-color: #005676;

}

.asidemenuadmin > ul > li > ul > li {
  padding: 10px;
  width: -50px;
  color: white;
  border-bottom: #167ba0 1px solid;
  border-radius: 5px 5px 0 0;
}

.asidemenuadmin > ul > li > ul > li> a {
  color: white;
  text-decoration: none;
}

.asidemenuadmin > ul > li  a {
  text-decoration: none;
  width: 100%;
}

.asidemenuadmin li > ul {
  position: absolute;
  margin-left: 97%;
  top: 0px;
  width: 300px;
  background-color: #003876;
  display: none;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.18);
}

.asidemenuadmin li > ul::before {
  content: "";
  position: absolute;
  top: 9px;
  left: -20px;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent#003876 transparent transparent;
}

.asidemenuadmin li:hover > ul {
  display: block;
}

.logoinstitucion{
  width: 100%;
  margin:  10px 0;
  padding: 10px;
  border: solid 1px rgb(204, 204, 204);
  border-radius: 5px;
}

.ctn-logonav{
  background-color: white;
  border-radius: 0 0 0 0;

}

@media only screen and (max-width: 991.5px) {
  .asidemenuadmin ul {
    list-style: none;
    padding: 0;
    border: solid 1px rgb(204, 204, 204);
    border-radius: 5px;
  }
  
  .asidemenuadmin > ul > li:hover {
    font-weight: 600;
    transition-duration: 0.1s;
    color: #003876;
    border-left: 2px solid #003876;
    display: flex;
    flex-direction: column;
  }

  .asidemenuadmin > ul > li:hover > i {
    display: block;
    transform: rotate(90deg); 
  }
  
  .asidemenuadmin > ul > li {
    border-bottom: solid 1px rgb(204, 204, 204);
    position: relative;
    padding: 10px 7px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
    cursor: pointer;
    
  }
  
  .asidemenuadmin > ul > li > ul > li:hover {
    background-color: #005676;
  
  }
  
  .asidemenuadmin > ul > li > ul > li {
    padding: 10px;
    width: -50px;
    color: white;
    border-bottom: #167ba0 1px solid;
    border-radius: 5px 5px 0 0;
  }
  
  .asidemenuadmin > ul > li > ul > li> a {
    color: white;
    text-decoration: none;
  }
  
  .asidemenuadmin > ul > li  a {
    text-decoration: none;
    width: 100%;
  }
  
  .asidemenuadmin li > ul {
    position: unset;
    margin-left: 0%;
    top: 0px;
    width: 100%;
    background-color: #003876;
    display: none;
    z-index: 1000;
    border-radius: 4px;
    box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.18);
    -webkit-box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.18);
  }
  
  .asidemenuadmin li > ul::before {
    content: "";
    position: absolute;
    display: none;
    top: 9px;
    left: -20px;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent#003876 transparent transparent;
  }
  
  .asidemenuadmin li:hover > ul {
    display: block;
  }
  
  .logoinstitucion{
    width: 100%;
    margin:  10px 0;
    padding: 10px;
    border: solid 1px rgb(204, 204, 204);
    border-radius: 5px;
  }
  
  .ctn-logonav{
    background-color: white;
    border-radius: 0 0 0 0;
    
  
  }
}

